module.exports = {
  development: {
    clientUrl: "http://localhost:3000",
    apiUrl: "http://localhost:5000",
  },
  test: {
    clientUrl: "https://www.gao.academy",
    apiUrl: "https://gao-academy-api.herokuapp.com",
  },
  production: {
    clientUrl: "https://www.gao.academy",
    apiUrl: "https://gao-academy-api.herokuapp.com",
  },
};
